.animatedBorder {
    transition: color 0.25s;
    position: relative;
    border-color: var(--primary_03, rgba(94, 195, 202, 0.3)) !important;
    border-width: 1px !important;
}

.animatedBorder::before,
.animatedBorder::after {
    content: '';
    position: absolute;
    border: 1px solid transparent;
    width: 0;
    height: 0;
    box-sizing: border-box;
}

/* Top and right borders (expands right, then down) */
.animatedBorder::before {
    top: -1px;
    left: 1px;
    border-top-right-radius: 4px; /* Optional: makes the corners smoother */
    border-top-color: var(--primary, #5ec3ca);
    border-right-color: var(--primary, #5ec3ca);
    animation: animatedBorder-before 1s ease-out infinite;
}

/* Bottom and left borders (expands left, then up) */
.animatedBorder::after {
    bottom: -1px;
    right: 1px;
    border-bottom-left-radius: 4px; /* Optional: makes the corners smoother */
    border-bottom-color: var(--primary, #5ec3ca);
    border-left-color: var(--primary, #5ec3ca);
    animation: animatedBorder-after 1s ease-out infinite;
}

/* Keyframes for the top-right borders */
@keyframes animatedBorder-before {
    0% {
        opacity: 0.3;
        width: 0;
        height: 0;
    }
    25% {
        opacity: 1;
        width: 100%;
        box-sizing: border-box;
        height: 0;
    }
    75% {
        opacity: 1;
    }
    100% {
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        opacity: 0.3;
    }
}

/* Keyframes for the bottom-left borders */
@keyframes animatedBorder-after {
    0% {
        opacity: 0.3;
        width: 0;
        height: 0;
    }
    50% {
        opacity: 1;
        width: 100%;
        box-sizing: border-box;
        height: 0;
    }
    75% {
        opacity: 1;
    }
    100% {
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        opacity: 0.3;
    }
}
