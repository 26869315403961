.ant-select-dropdown {
    font-family: Lato !important;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 14px;
    font-variant: initial;
    background-color: #fff;
    border-radius: 4px;
    outline: none;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-select-selection:hover {
    border-color: #5ec3ca;
    border-right-width: 1px !important;
}

.ant-select-selection {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid rgba(0, 132, 137, 0.5);
    border-top-width: 1.02px;
    border-radius: 4px;
    outline: none;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active,
.ant-select-open .ant-select-selection {
    border-color: #5ec3ca;
}
