html {
    -webkit-text-size-adjust: none;

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: var(--scroll-bar-track-color, #f1f1f1);
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--scroll-bar-handle-color, #d1d1d1);
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--scroll-bar-handle-color, #d1d1d1);
    }

    input {
        outline: none !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 50px
            var(--base-overlays-neutral-alpha-100-full-color, #f6f6f6) inset;
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: var(--text-strong, #1a1a1a) !important;
    }
    input:-internal-autofill-selected {
        background: var(--base-overlays-neutral-alpha-100-full-color, #f6f6f6);
    }

    :where(.css-dev-only-do-not-override-77tu7h).ant-radio-wrapper
        .ant-radio-checked
        .ant-radio-inner {
        border-color: var(--background) !important;
        background-color: var(--background) !important;
    }
}

:root {
    --rsbs-handle-bg: #8b8b8b;
    --rsbs-max-w: auto;
    --rsbs-ml: env(safe-area-inset-left);
    --rsbs-mr: env(safe-area-inset-right);
    --rsbs-antigap-scale-y: 0;
    --rsbs-backdrop-bg: rgba(0, 0, 0, 0);
    --rsbs-bg: #f9f9f9;
    --rsbs-backdrop-opacity: 1;
    --rsbs-content-opacity: 1;
    --rsbs-overlay-h: 0px;
    --rsbs-overlay-rounded: 16px;
    --rsbs-overlay-translate-y: 0px;

    /* base colours */

    --bg-color: #fff;
    --bg-color-70: rgba(255, 255, 255, 0.753);
    --background-overlay-light: rgba(255, 255, 255, 0.96);
    --bg-color-85: rgba(255, 255, 255, 0.85);
    --off-bg-color: #fafafa;
    --scroll-bar-handle-color: #d1d1d1;
    --scroll-bar-track-color: #f1f1f1;
    --text-muted: #b3b3b3;
    --text-default: #666666;
    --text-darker: #616161;
    --text-strong: #1a1a1a;
    --text-muted-light: #b9b9b9;
    --text-disabled: #c6c6c6;
    --text-disabled-light: #c6c6c6;
    --border-muted: #e5e5e5;
    --base-overlays-white: #fff;
    --box-shadow: rgba(0, 0, 0, 0.08);
    --box-shadow-stronger: rgba(0, 0, 0, 0.08);
    --gallery-image-bg: rgba(227, 227, 227, 0.95);
    --loader-bg: rgba(255, 255, 255, 0.95);
    --file-sufix-pdf: rgba(223, 111, 111, 0.08);
    --file-sufix-img: rgba(103, 160, 227, 0.08);
    --file-sufix-docs: rgba(170, 111, 216, 0.08);
    --base-overlays-neutral-alpha-200: rgba(26, 26, 26, 0.16);
    --base-overlays-neutral-alpha-150: rgba(26, 26, 26, 0.08);
    --base-overlays-neutral-alpha-100: rgba(26, 26, 26, 0.04);
    --base-overlays-neutral-alpha-50: rgba(26, 26, 26, 0.02);
    --base-overlays-neutral-alpha-100-full-color: #f6f6f6;
    --shadow-cards-bg: #fff;
    --road-color: #f6f6f6;
    --modal-side-sheet-pop-over-bg: #fff;
    --slider-banner-pop-over-bg: #fafafa;
    --base-overlays-yello-alpha-200: #fffaf1;
    --base-overlays-teal-alpha-200: #f2fafb;
    --yellow-border-default: #fac86f;

    --primary: #5ec3ca;
    --primary_80: rgba(94, 195, 202, 0.8);
    --primary_30: rgba(94, 195, 202, 0.3);
    --primary_04: rgba(94, 195, 202, 0.04);
    --primary_08: rgba(94, 195, 202, 0.08);
    --primary_09: rgba(94, 195, 202, 0.09);
    --primary_16: rgba(94, 195, 202, 0.16);
    --primary_20: rgba(94, 195, 202, 0.2);
    --primary_lighter_and_brighter: #f5feff;
    --primary_strong: #009ea4;
    --primary_stronger: #157076;
    --error: #df6f6f;
    --error_50: rgba(223, 111, 111, 0.5);
    --error_08: rgba(223, 111, 111, 0.08);
    --warning: #f9bb4b;

    --text_neutral_default: #cccccc;
    --text_neutral_default_500: #808080;
    --text_primary_muted: #9fbfc0;
    --border_muted_subtle: #f6f6f6;
    --border_muted_lighter: #f0f0f0;
    --border_muted_light: #ebebeb;
    --border_muted: #e5e5e5;
    --border_muted_neutral: #c0c0c0;
    --border_neutral: #a5a5a5;
    --border_neutral_strong: #8b8b8b;
    --border_muted_primary: #cedfdf;
    --background_default: #ffffff;
    --background_default_20: #ffffff33;
    --background_neutral_subtle: #fafafa;
    --background_neutral_subtle_light: #fafdfd;
    --background_neutral_subtle_lighter: #fdfdfd;
    --background_neutral_soft: #f8f8f8;
    --background_primary_subtle_2: #f5fcfc;
    --background_primary_subtle: #f5fafa;
    --background_primary_soft: #f2fafb;
    --background_primary_muted: #e5f5f7;
    --background_neutral: #f4f4f4;
    --background_disabled_neutral: #d9d9d9;
    --background_neutral_active: #333333;
    --background_primary_soft_16: rgba(0, 169, 175, 0.16);
    --background_neutral_overlay_40: rgba(26, 26, 26, 0.04);
    --background_neutral_overlay_02: rgba(26, 26, 26, 0.02);
    --background_neutral_backdrop_70: rgba(26, 26, 26, 0.7);
    --background_neutral_backdrop_97: rgba(250, 250, 250, 0.97);
    --service_label: #73bfa4;
    --maintenance_label: #abaaed;
    --restoration_label: #bb6d78;
    --admin_label: #889c99;
    --insurance_label: #5d7ebf;
    --history_label: #c87fbd;
    --events_label: #ffae53;
    --other_label: #be8f76;
    --shadow_strongest_03: rgba(0, 0, 0, 0.03);
    --shadow_strongest_04: rgba(0, 0, 0, 0.04);
    --shadow_strongest_09: rgba(0, 0, 0, 0.09);
    --shadow_strongest_65: rgba(0, 0, 0, 0.65);
    --strong_tooltip: #4b4b4b;
    --supp_Lavender_100: rgba(170, 111, 216, 1);
    --supp_lavender_8: #f8f3fc;
    --supp_lavender_30: #e5d4f3;
    --garage_icons_bg: #fff;
    --garage-placeholder-gradient-1: linear-gradient(
        90deg,
        rgba(26, 26, 26, 0.05) 0%,
        rgba(128, 128, 128, 0) 100%
    );
    --garage-placeholder-gradient-2: linear-gradient(
        90deg,
        rgba(26, 26, 26, 0.03) 0%,
        rgba(128, 128, 128, 0) 100%
    );

    /* insurance initial base */
    --insurance_details_green: #85c98b;
    --insurance_details_green_progress_unfinished: rgba(133, 201, 139, 0.16);
    --insurance_details_green_border: #daefdc;
    --insured_vehicles_blue: #81d4df;
    --insured_vehicles_blue_progress_unfinished: rgba(129, 212, 223, 0.16);
    --insured_vehicles_blue_border: #d9f2f5;
    --everyday_vehicles_blue: #67a0e3;
    --everyday_vehicles_blue_progress_unfinished: rgba(103, 160, 227, 0.16);
    --everyday_vehicles_blue_border: #d2e3f7;
    --main_driver_purple: #aa6fd8;
    --main_driver_purple_progress_unfinished: rgba(170, 111, 216, 0.16);
    --main_driver_purple_border: #e5d4f3;
    --other_drivers_pink: #e39de4;
    --other_drivers_pink_progress_unfinished: rgba(227, 157, 228, 0.16);
    --other_drivers_pink_border: #f7e1f7;
    --addresses_blue_border: #cfedef;
    --shadow_04: rgba(26, 26, 26, 0.07);
    --shavow_08: rgba(26, 26, 26, 0.08);

    /* insurance sections */

    --insurance_details-primary_100: rgba(133, 201, 139, 1);
    --insurance_details-primary_60: #b6dfb9;
    --insurance_details-primary_30: #daefdc;
    --insurance_details-primary_24: #e2f2e3;
    --insurance_details-primary_16: rgba(133, 201, 139, 0.16);
    --insurance_details-primary_08: #f5fbf6;
    --insurance_details-primary_04: #fafdfa;
    --insurance_details-primary_text: #72af77;

    --insurance-vehicles_to_insure-primary_100: #81d4df;
    --insurance-vehicles_to_insure-primary_60: #b3e5ec;
    --insurance-vehicles_to_insure-primary_30: #d9f2f5;
    --insurance-vehicles_to_insure-primary_24: #e1f5f7;
    --insurance-vehicles_to_insure-primary_16: rgba(129, 212, 223, 0.16);
    --insurance-vehicles_to_insure-primary_08: #f5fcfc;
    --insurance-vehicles_to_insure-primary_04: #fafdfe;
    --insurance-vehicles_to_insure-primary_text: #53acb8;

    --insurance-everyday_vehicles-primary_100: #67a0e3;
    --insurance-everyday_vehicles-primary_60: #a4c6ee;
    --insurance-everyday_vehicles-primary_30: #d2e3f7;
    --insurance-everyday_vehicles-primary_24: #dbe8f8;
    --insurance-everyday_vehicles-primary_16: rgba(103, 160, 227, 0.16);
    --insurance-everyday_vehicles-primary_08: #f3f7fd;
    --insurance-everyday_vehicles-primary_04: #f9fbfe;
    --insurance-everyday_vehicles-primary_text: #4a7fbe;

    --insurance-main_policy_holder-primary_100: #aa6fd8;
    --insurance-main_policy_holder-primary_60: #cca9e8;
    --insurance-main_policy_holder-primary_30: #e5d4f3;
    --insurance-main_policy_holder-primary_24: #ebdcf6;
    --insurance-main_policy_holder-primary_16: rgba(170, 111, 216, 0.16);
    --insurance-main_policy_holder-primary_08: #f8f3fc;
    --insurance-main_policy_holder-primary_04: #fcf9fd;
    --insurance-main_policy_holder-primary_text: #aa6fd8;

    --insurance-drivers-primary_100: rgba(165, 168, 242, 1);
    --insurance-drivers-primary_60: rgba(165, 168, 242, 0.6);
    --insurance-drivers-primary_30: rgba(165, 168, 242, 0.3);
    --insurance-drivers-primary_24: rgba(165, 168, 242, 0.24);
    --insurance-drivers-primary_16: rgba(165, 168, 242, 0.16);
    --insurance-drivers-primary_08: #f8f8fe;
    --insurance-drivers-primary_04: #fbfcfe;
    --insurance-drivers-primary_text: rgba(165, 168, 242, 1);

    --insurance-addresses-primary_100: #5ec3ca;
    --insurance-addresses-primary_60: #9edbdf;
    --insurance-addresses-primary_30: #cfedef;
    --insurance-addresses-primary_24: #d8f1f2;
    --insurance-addresses-primary_16: #e5e5e5;
    --insurance-addresses-primary_08: #f2fafb;
    --insurance-addresses-primary_04: rgba(94, 195, 202, 0.04);
    --insurance-addresses-primary_text: #1a1a1a;

    --insurance-addresses-primary_100: #3d8374;
    --insurance-addresses-primary_60: #8bb5ac;
    --insurance-addresses-primary_30: #d0e1de;
    --insurance-addresses-primary_24: #eff5f4;
    --insurance-addresses-primary_16: #eff5f4;
    --insurance-addresses-primary_08: #f2fafb;
    --insurance-addresses-primary_04: rgba(94, 195, 202, 0.04);
    --insurance-addresses-primary_text: #1a1a1a;

    /* history file */

    --history-file-service-strong: #1f453d;
    --history-file-service-primary_100: rgba(61, 131, 116, 1);
    --history-file-service-primary_30: rgba(197, 218, 213, 1);
    --history-file-service-primary_16: rgba(61, 131, 116, 0.16);
    --history-file-service-primary_04: rgba(129, 212, 223, 0.04);
    --history-file-service-bg_gradient: linear-gradient(
        90deg,
        #eff5f4 0%,
        rgba(239, 245, 244, 0.3) 100%
    );

    --history-file-maintenance-strong: #1f453d;
    --history-file-maintenance-primary_100: rgba(165, 168, 242, 1);
    --history-file-maintenance-primary_30: rgba(228, 229, 251, 1);
    --history-file-maintenance-primary_16: rgba(165, 168, 242, 0.16);
    --history-file-maintenance-primary_04: rgba(165, 168, 242, 0.04);
    --history-file-maintenance-bg_gradient: linear-gradient(
        90deg,
        #f8f8fe 0%,
        rgba(248, 248, 254, 0.3) 100%
    );

    --history-file-restoration-strong: #1f453d;
    --history-file-restoration-primary_100: rgba(184, 100, 150, 1);
    --history-file-restoration-primary_30: rgba(234, 208, 223, 1);
    --history-file-restoration-primary_16: rgba(184, 100, 150, 0.16);
    --history-file-restoration-primary_04: rgba(184, 100, 150, 0.04);
    --history-file-restoration-bg_gradient: linear-gradient(
        90deg,
        #f9f3f7 0%,
        rgba(249, 243, 247, 0.3) 100%
    );

    --history-file-admin-strong: #1f453d;
    --history-file-admin-primary_100: rgba(144, 174, 170, 1);
    --history-file-admin-primary_30: rgba(222, 231, 229, 1);
    --history-file-admin-primary_16: rgba(144, 174, 170, 0.16);
    --history-file-admin-primary_04: rgba(165, 168, 242, 0.04);
    --history-file-admin-bg_gradient: linear-gradient(
        90deg,
        #f6f9f8 0%,
        rgba(246, 249, 248, 0.3) 100%
    );

    --history-file-insurance-strong: #1f453d;
    --history-file-insurance-primary_100: rgba(103, 160, 227, 1);
    --history-file-insurance-primary_30: rgba(210, 227, 247, 1);
    --history-file-insurance-primary_16: rgba(103, 160, 227, 0.16);
    --history-file-insurance-primary_04: rgba(103, 160, 227, 0.04);
    --history-file-insurance-bg_gradient: linear-gradient(
        90deg,
        #f3f7fd 0%,
        rgba(243, 247, 253, 0.3) 100%
    );

    --history-file-history-strong: #1f453d;
    --history-file-history-primary_100: rgba(170, 111, 216, 1);
    --history-file-history-primary_30: rgba(229, 212, 243, 1);
    --history-file-history-primary_16: rgba(170, 111, 216, 0.16);
    --history-file-history-primary_04: rgba(170, 111, 216, 0.04);
    --history-file-history-bg_gradient: linear-gradient(
        90deg,
        #f8f3fc 0%,
        rgba(248, 243, 252, 0.3) 100%
    );

    --history-file-events-strong: #1f453d;
    --history-file-events-primary_100: rgba(245, 162, 64, 1);
    --history-file-events-primary_30: rgba(252, 227, 198, 1);
    --history-file-events-primary_16: rgba(245, 162, 64, 0.16);
    --history-file-events-primary_04: rgba(245, 162, 64, 0.04);
    --history-file-events-bg_gradient: linear-gradient(
        90deg,
        #fef8f0 0%,
        rgba(254, 248, 240, 0.3) 100%
    );

    --history-file-other-strong: #1f453d;
    --history-file-other-primary_100: rgba(213, 131, 86, 1);
    --history-file-other-primary_30: rgba(242, 218, 204, 1);
    --history-file-other-primary_16: rgba(213, 131, 86, 0.16);
    --history-file-other-primary_04: rgba(213, 131, 86, 0.04);
    --history-file-other-bg_gradient: linear-gradient(
        90deg,
        #fcf5f1 0%,
        rgba(252, 245, 241, 0.3) 100%
    );

    --history-file-others-strong: #1f453d;
    --history-file-others-primary_100: rgba(213, 131, 86, 1);
    --history-file-others-primary_30: rgba(242, 218, 204, 1);
    --history-file-others-primary_16: rgba(213, 131, 86, 0.16);
    --history-file-others-primary_04: rgba(213, 131, 86, 0.04);
    --history-file-others-bg_gradient: linear-gradient(
        90deg,
        #fcf5f1 0%,
        rgba(252, 245, 241, 0.3) 100%
    );

    /* journey */

    --journey-car_profile-primary_100: #ebf8fa;
    --journey-car_profile-primary_200: #e1f5f7;
    --journey-car_profile-primary_300: #d9f2f5;
    --journey-car_profile-primary_400: #b3e5ec;
    --journey-car_profile-primary_500: #81d4df;
    --journey-car_profile-primary_500_90: #81d4dfe5;
    --journey-car_profile-primary_500_80: #81d4dfcc;
    --journey-car_profile-primary_500_70: #81d4dfb2;
    --journey-car_profile-primary_500_60: #81d4df99;
    --journey-car_profile-primary_500_50: #81d4df80;
    --journey-car_profile-primary_500_24: #81d4df3d;
    --journey-car_profile-primary_500_16: #81d4df29;
    --journey-car_profile-primary_500_08: #81d4df14;
    --journey-car_profile-primary_600: #53acb8;
    --journey-car_profile-primary_700: #53acb8;
    --journey-car_profile-primary_900: #336c74;

    --journey-history_file-primary_100: #e7f0fb;
    --journey-history_file-primary_200: #dbe8f8;
    --journey-history_file-primary_300: #d2e3f7;
    --journey-history_file-primary_400: #a4c6ee;
    --journey-history_file-primary_500: #67a0e3;
    --journey-history_file-primary_500_90: rgba(103, 160, 227, 0.9);
    --journey-history_file-primary_500_80: rgba(103, 160, 227, 0.8);
    --journey-history_file-primary_500_70: rgba(103, 160, 227, 0.7);
    --journey-history_file-primary_500_60: rgba(103, 160, 227, 0.6);
    --journey-history_file-primary_500_50: rgba(103, 160, 227, 0.5);
    --journey-history_file-primary_500_24: rgba(103, 160, 227, 0.24);
    --journey-history_file-primary_500_16: #67a0e329;
    --journey-history_file-primary_500_08: #f3f8fd;
    --journey-history_file-primary_600: #4a7fbe;
    --journey-history_file-primary_700: #4a7fbe;
    --journey-history_file-primary_900: #2e4f77;

    --journey-insurance-primary_100: #f1f1fd;
    --journey-insurance-primary_200: #e9eafc;
    --journey-insurance-primary_300: #e4e5fb;
    --journey-insurance-primary_400: #c9cbf7;
    --journey-insurance-primary_500: #a5a8f2;
    --journey-insurance-primary_500_90: #a5a8f2e5;
    --journey-insurance-primary_500_80: #a5a8f2cc;
    --journey-insurance-primary_500_70: #a5a8f2b2;
    --journey-insurance-primary_500_60: #a5a8f299;
    --journey-insurance-primary_500_50: #a5a8f280;
    --journey-insurance-primary_500_24: #a5a8f23d;
    --journey-insurance-primary_500_16: #a5a8f229;
    --journey-insurance-primary_500_08: #a5a8f214;
    --journey-insurance-primary_600: #8e90d8;
    --journey-insurance-primary_700: #8183c5;
    --journey-insurance-primary_900: #656599;

    --journey-share_your_car-primary_100: #f1e8f9;
    --journey-share_your_car-primary_200: #ebdcf6;
    --journey-share_your_car-primary_300: #e5d4f3;
    --journey-share_your_car-primary_400: #cca9e8;
    --journey-share_your_car-primary_500: #aa6fd8;
    --journey-share_your_car-primary_500_90: #aa6fd8e5;
    --journey-share_your_car-primary_500_80: #aa6fd8cc;
    --journey-share_your_car-primary_500_70: #aa6fd8b2;
    --journey-share_your_car-primary_500_60: #aa6fd899;
    --journey-share_your_car-primary_500_50: #aa6fd880;
    --journey-share_your_car-primary_500_24: #aa6fd83d;
    --journey-share_your_car-primary_500_16: #aa6fd829;
    --journey-share_your_car-primary_500_08: #aa6fd814;
    --journey-share_your_car-primary_500_04: rgba(170, 111, 216, 0.04);
    --journey-share_your_car-primary_600: #9964c3;
    --journey-share_your_car-primary_900: #8958af;

    --journey-past_cars-primary_100: #f4e6ee;
    --journey-past_cars-primary_200: #eedae6;
    --journey-past_cars-primary_300: #ead0df;
    --journey-past_cars-primary_400: #d4a2c0;
    --journey-past_cars-primary_500: #b86496;
    --journey-past_cars-primary_500_90: rgba(184, 100, 150, 1);
    --journey-past_cars-primary_500_80: rgba(184, 100, 150, 0.8);
    --journey-past_cars-primary_500_70: rgba(184, 100, 150, 0.7);
    --journey-past_cars-primary_500_60: rgba(184, 100, 150, 0.6);
    --journey-past_cars-primary_500_50: rgba(184, 100, 150, 0.5);
    --journey-past_cars-primary_500_24: rgba(184, 100, 150, 0.24);
    --journey-past_cars-primary_500_16: rgba(184, 100, 150, 0.16);
    --journey-past_cars-primary_500_08: rgba(184, 100, 150, 0.08);
    --journey-past_cars-primary_600: #a75b88;
    --journey-past_cars-primary_900: #733e5d;

    --journey-handover-primary_100: #ebf6ec;
    --journey-handover-primary_200: #e2f2e3;
    --journey-handover-primary_300: #daefdc;
    --journey-handover-primary_400: #b6dfb9;
    --journey-handover-primary_500: #85c98b;
    --journey-handover-primary_500_90: rgba(133, 201, 139, 0.9);
    --journey-handover-primary_500_80: rgba(133, 201, 139, 0.8);
    --journey-handover-primary_500_70: rgba(133, 201, 139, 0.7);
    --journey-handover-primary_500_60: rgba(133, 201, 139, 0.6);
    --journey-handover-primary_500_50: rgba(133, 201, 139, 0.5);
    --journey-handover-primary_500_24: rgba(133, 201, 139, 0.24);
    --journey-handover-primary_500_16: rgba(133, 201, 139, 0.16);
    --journey-handover-primary_500_08: rgba(133, 201, 139, 0.8);
    --journey-handover-primary_600: #72af77;
    --journey-handover-primary_900: #38563b;

    --journey-sell_your_car-primary_100: #e0ebe9;
    --journey-sell_your_car-primary_200: #d0e1de;
    --journey-sell_your_car-primary_300: #c5dad5;
    --journey-sell_your_car-primary_400: #8bb5ac;
    --journey-sell_your_car-primary_500: #3d8374;
    --journey-sell_your_car-primary_500_90: #3d8374e5;
    --journey-sell_your_car-primary_500_80: #3d8374cc;
    --journey-sell_your_car-primary_500_70: #3d8374b2;
    --journey-sell_your_car-primary_500_60: #3d837499;
    --journey-sell_your_car-primary_500_50: #3d837480;
    --journey-sell_your_car-primary_500_24: #3d83743d;
    --journey-sell_your_car-primary_500_16: #3d837429;
    --journey-sell_your_car-primary_500_08: #3d837414;
    --journey-sell_your_car-primary_600: #3d8374;
    --journey-sell_your_car-primary_700: #3d8374;
    --journey-sell_your_car-primary_900: #1f453d;

    --journey-your_profile-primary_100: #edf2f1;
    --journey-your_profile-primary_200: #e4eceb;
    --journey-your_profile-primary_300: #dee7e5;
    --journey-your_profile-primary_400: #bccecc;
    --journey-your_profile-primary_500: #90aeaa;
    --journey-your_profile-primary_500_90: rgba(144, 174, 170, 0.9);
    --journey-your_profile-primary_500_80: rgba(144, 174, 170, 0.8);
    --journey-your_profile-primary_500_70: rgba(144, 174, 170, 0.7);
    --journey-your_profile-primary_500_60: rgba(144, 174, 170, 0.6);
    --journey-your_profile-primary_500_50: rgba(144, 174, 170, 0.5);
    --journey-your_profile-primary_500_24: rgba(144, 174, 170, 0.24);
    --journey-your_profile-primary_500_16: #edf2f1;
    --journey-your_profile-primary_500_08: #f6f9f8;
    --journey-your_profile-primary_600: #7f9a97;
    --journey-your_profile-primary_700: #7f9a97;
    --journey-your_profile-primary_900: #536563;
}

html.dark {
    /* base colours */

    --rsbs-bg: #121212;

    --bg-color: #121212;
    --bg-color-70: rgba(18, 18, 18, 0.753);
    --background-overlay-light: rgba(0, 0, 0, 0.9);
    --bg-color-85: rgba(26, 26, 26, 0.85);
    --off-bg-color: #161a1a;
    --scroll-bar-handle-color: #494949;
    --scroll-bar-track-color: #222;
    --text-muted: #808080;
    --text-default: #b3b3b3;
    --text-darker: #b0b0b0;
    --text-strong: #fff;
    --text-muted-light: #b9b9b9;
    --text-disabled: #343434;
    --text-disabled-light: #808080;
    --border-muted: #4c4c4c;
    --base-overlays-white: #000;
    --box-shadow: rgba(0, 0, 0, 0.32);
    --box-shadow-stronger: rgba(82, 82, 82, 0.32);
    --gallery-image-bg: rgba(26, 26, 26, 0.95);
    --loader-bg: rgba(26, 26, 26, 0.9);
    --file-sufix-pdf: rgba(223, 111, 111, 0.08);
    --file-sufix-img: rgba(103, 160, 227, 0.08);
    --file-sufix-docs: rgba(170, 111, 216, 0.08);
    --base-overlays-neutral-alpha-200: rgba(255, 255, 255, 0.16);
    --base-overlays-neutral-alpha-150: rgba(255, 255, 255, 0.12);
    --base-overlays-neutral-alpha-100: rgba(255, 255, 255, 0.08);
    --base-overlays-neutral-alpha-50: rgba(255, 255, 255, 0.04);
    --base-overlays-neutral-alpha-100-full-color: #252525;
    --shadow-cards-bg: #222;
    --road-color: #2c2c2c;
    --modal-side-sheet-pop-over-bg: #2c2c2c;
    --slider-banner-pop-over-bg: ##2c2c2c;
    --base-overlays-yello-alpha-200: rgba(249, 187, 75, 0.16);
    --base-overlays-teal-alpha-200: rgba(94, 195, 202, 0.16);
    --yellow-border-default: #6e4816;

    --primary: #5ec3ca;
    --primary_80: rgba(94, 195, 202, 0.8);
    --primary_30: rgba(94, 195, 202, 0.3);
    --primary_04: rgba(94, 195, 202, 0.04);
    --primary_08: rgba(94, 195, 202, 0.08);
    --primary_09: rgba(94, 195, 202, 0.09);
    --primary_16: rgba(94, 195, 202, 0.16);
    --primary_20: rgba(94, 195, 202, 0.2);
    --primary_lighter_and_brighter: #f5feff;
    --primary_strong: #009ea4;
    --primary_stronger: #157076;
    --error: #df6f6f;
    --error_50: rgba(67, 32, 32, 0.5);
    --error_08: rgba(223, 111, 111, 0.08);
    --warning: #f9bb4b;

    --text_neutral_default: #cccccc;
    --text_neutral_default_500: #808080;
    --text_primary_muted: #9fbfc0;
    --border_muted_subtle: #333;
    --border_muted_lighter: #b9b9b9;
    --border_muted_light: #ebebeb;
    --border_muted: #4c4c4c;
    --border_muted_neutral: #666;
    --border_neutral: #a5a5a5;
    --border_neutral_strong: #8b8b8b;
    --border_muted_primary: #cedfdf;
    --background_default: #ffffff;
    --background_default_20: #ffffff33;
    --background_neutral_subtle: #fafafa;
    --background_neutral_subtle_light: #fafdfd;
    --background_neutral_subtle_lighter: #fdfdfd;
    --background_neutral_soft: #202020;
    --background_primary_subtle_2: #f5fcfc;
    --background_primary_subtle: #222;
    --background_primary_soft: #224649;
    --background_primary_muted: #e5f5f7;
    --background_neutral: rgba(26, 26, 26, 0.7);
    --background_disabled_neutral: #444;
    --background_neutral_active: #b9b9b9;
    --background_primary_soft_16: rgba(0, 169, 175, 0.16);
    --background_neutral_overlay_40: rgba(26, 26, 26, 0.04);
    --background_neutral_overlay_02: rgba(26, 26, 26, 0.02);
    --background_neutral_backdrop_70: rgba(26, 26, 26, 0.7);
    --background_neutral_backdrop_97: rgba(250, 250, 250, 0.97);
    --service_label: #73bfa4;
    --maintenance_label: #abaaed;
    --restoration_label: #bb6d78;
    --admin_label: #889c99;
    --insurance_label: #5d7ebf;
    --history_label: #c87fbd;
    --events_label: #ffae53;
    --other_label: #be8f76;
    --shadow_strongest_03: rgba(0, 0, 0, 0.03);
    --shadow_strongest_04: rgba(0, 0, 0, 0.04);
    --shadow_strongest_09: rgba(0, 0, 0, 0.09);
    --shadow_strongest_65: rgba(0, 0, 0, 0.65);
    --strong_tooltip: #4b4b4b;
    --supp_Lavender_100: rgba(170, 111, 216, 1);
    --supp_lavender_8: rgba(170, 111, 216, 0.08);
    --supp_lavender_30: #694387;
    --garage_icons_bg: #2c2c2c;
    --garage-placeholder-gradient-1: linear-gradient(
        90deg,
        rgba(192, 192, 192, 0.14) 55%,
        rgba(255, 255, 255, 0) 100%
    );
    --garage-placeholder-gradient-2: linear-gradient(
        90deg,
        rgba(167, 167, 167, 0.06) 40%,
        rgba(255, 255, 255, 0.06) 100%
    );

    /* insurance initial base */
    --insurance_details_green: #85c98b;
    --insurance_details_green_progress_unfinished: rgba(133, 201, 139, 0.16);
    --insurance_details_green_border: #daefdc;
    --insured_vehicles_blue: #81d4df;
    --insured_vehicles_blue_progress_unfinished: rgba(129, 212, 223, 0.16);
    --insured_vehicles_blue_border: #d9f2f5;
    --everyday_vehicles_blue: #67a0e3;
    --everyday_vehicles_blue_progress_unfinished: rgba(103, 160, 227, 0.16);
    --everyday_vehicles_blue_border: #d2e3f7;
    --main_driver_purple: #aa6fd8;
    --main_driver_purple_progress_unfinished: rgba(170, 111, 216, 0.16);
    --main_driver_purple_border: #e5d4f3;
    --other_drivers_pink: #e39de4;
    --other_drivers_pink_progress_unfinished: rgba(227, 157, 228, 0.16);
    --other_drivers_pink_border: #f7e1f7;
    --addresses_blue_border: #cfedef;
    --shadow_04: rgba(26, 26, 26, 0.07);
    --shavow_08: rgba(26, 26, 26, 0.08);

    /* insurance sections */
    --insurance_details-primary_100: #85c98b;
    --insurance_details-primary_60: #72af77;
    --insurance_details-primary_30: #38563b;
    --insurance_details-primary_24: #4d7651;
    --insurance_details-primary_16: rgba(133, 201, 139, 0.16);
    --insurance_details-primary_08: #223524;
    --insurance_details-primary_04: rgba(133, 201, 139, 0.04);
    --insurance_details-primary_text: #72af77;

    --insurance-vehicles_to_insure-primary_100: #81d4df;
    --insurance-vehicles_to_insure-primary_60: #b3e5ec;
    --insurance-vehicles_to_insure-primary_30: #336c74;
    --insurance-vehicles_to_insure-primary_24: #e1f5f7;
    --insurance-vehicles_to_insure-primary_16: rgba(129, 212, 223, 0.16);
    --insurance-vehicles_to_insure-primary_08: #1f4247;
    --insurance-vehicles_to_insure-primary_04: #fafdfe;
    --insurance-vehicles_to_insure-primary_text: #53acb8;

    --insurance-everyday_vehicles-primary_100: #67a0e3;
    --insurance-everyday_vehicles-primary_60: #365d8c;
    --insurance-everyday_vehicles-primary_30: #2e4f77;
    --insurance-everyday_vehicles-primary_24: #365d8c;
    --insurance-everyday_vehicles-primary_16: rgba(103, 160, 227, 0.16);
    --insurance-everyday_vehicles-primary_08: #1c314a;
    --insurance-everyday_vehicles-primary_04: #f9fbfe;
    --insurance-everyday_vehicles-primary_text: #4a7fbe;

    --insurance-main_policy_holder-primary_100: #aa6fd8;
    --insurance-main_policy_holder-primary_60: #cca9e8;
    --insurance-main_policy_holder-primary_30: #694387;
    --insurance-main_policy_holder-primary_24: #794e9b;
    --insurance-main_policy_holder-primary_16: rgba(170, 111, 216, 0.16);
    --insurance-main_policy_holder-primary_08: #3b264c;
    --insurance-main_policy_holder-primary_04: #fcf9fd;
    --insurance-main_policy_holder-primary_text: #aa6fd8;

    --insurance-drivers-primary_100: rgba(165, 168, 242, 1);
    --insurance-drivers-primary_60: rgba(165, 168, 242, 0.6);
    --insurance-drivers-primary_30: #656599;
    --insurance-drivers-primary_24: #7273ad;
    --insurance-drivers-primary_16: rgba(165, 168, 242, 0.16);
    --insurance-drivers-primary_08: #f8f8fe;
    --insurance-drivers-primary_04: #fbfcfe;
    --insurance-drivers-primary_text: rgba(165, 168, 242, 1);

    --insurance-addresses-primary_100: #5ec3ca;
    --insurance-addresses-primary_60: #9edbdf;
    --insurance-addresses-primary_30: #224649;
    --insurance-addresses-primary_24: #2e6063;
    --insurance-addresses-primary_16: #e5e5e5;
    --insurance-addresses-primary_08: #224649;
    --insurance-addresses-primary_04: rgba(94, 195, 202, 0.04);
    --insurance-addresses-primary_text: #1a1a1a;

    --insurance-addresses-primary_100: #367668;
    --insurance-addresses-primary_60: #8bb5ac;
    --insurance-addresses-primary_30: #d0e1de;
    --insurance-addresses-primary_24: #152e29;
    --insurance-addresses-primary_16: #eff5f4;
    --insurance-addresses-primary_08: #f2fafb;
    --insurance-addresses-primary_04: rgba(94, 195, 202, 0.04);
    --insurance-addresses-primary_text: #1a1a1a;

    /* history file */

    --history-file-service-strong: #1f453d;
    --history-file-service-primary_100: rgba(61, 131, 116, 1);
    --history-file-service-primary_30: rgba(61, 131, 116, 0.3);
    --history-file-service-primary_16: rgba(61, 131, 116, 0.16);
    --history-file-service-primary_04: rgba(129, 212, 223, 0.04);
    --history-file-service-bg_gradient: linear-gradient(
        90deg,
        #eff5f4 0%,
        rgba(239, 245, 244, 0.3) 100%
    );

    --history-file-maintenance-strong: #1f453d;
    --history-file-maintenance-primary_100: rgba(165, 168, 242, 1);
    --history-file-maintenance-primary_30: rgba(165, 168, 242, 0.3);
    --history-file-maintenance-primary_16: rgba(165, 168, 242, 0.16);
    --history-file-maintenance-primary_04: rgba(165, 168, 242, 0.04);
    --history-file-maintenance-bg_gradient: linear-gradient(
        90deg,
        #f8f8fe 0%,
        rgba(248, 248, 254, 0.3) 100%
    );

    --history-file-restoration-strong: #1f453d;
    --history-file-restoration-primary_100: rgba(184, 100, 150, 1);
    --history-file-restoration-primary_30: rgba(184, 100, 150, 0.3);
    --history-file-restoration-primary_16: rgba(184, 100, 150, 0.16);
    --history-file-restoration-primary_04: rgba(184, 100, 150, 0.04);
    --history-file-restoration-bg_gradient: linear-gradient(
        90deg,
        #f9f3f7 0%,
        rgba(249, 243, 247, 0.3) 100%
    );

    --history-file-admin-strong: #1f453d;
    --history-file-admin-primary_100: rgba(144, 174, 170, 1);
    --history-file-admin-primary_30: rgba(144, 174, 170, 0.3);
    --history-file-admin-primary_16: rgba(144, 174, 170, 0.16);
    --history-file-admin-primary_04: rgba(165, 168, 242, 0.04);
    --history-file-admin-bg_gradient: linear-gradient(
        90deg,
        #f6f9f8 0%,
        rgba(246, 249, 248, 0.3) 100%
    );

    --history-file-insurance-strong: #1f453d;
    --history-file-insurance-primary_100: rgba(103, 160, 227, 1);
    --history-file-insurance-primary_30: rgba(103, 160, 227, 0.3);
    --history-file-insurance-primary_16: rgba(103, 160, 227, 0.16);
    --history-file-insurance-primary_04: rgba(103, 160, 227, 0.04);
    --history-file-insurance-bg_gradient: linear-gradient(
        90deg,
        #f3f7fd 0%,
        rgba(243, 247, 253, 0.3) 100%
    );

    --history-file-history-strong: #1f453d;
    --history-file-history-primary_100: rgba(170, 111, 216, 1);
    --history-file-history-primary_30: rgba(170, 111, 216, 0.3);
    --history-file-history-primary_16: rgba(170, 111, 216, 0.16);
    --history-file-history-primary_04: rgba(170, 111, 216, 0.04);
    --history-file-history-bg_gradient: linear-gradient(
        90deg,
        #f8f3fc 0%,
        rgba(248, 243, 252, 0.3) 100%
    );

    --history-file-events-strong: #1f453d;
    --history-file-events-primary_100: rgba(245, 162, 64, 1);
    --history-file-events-primary_30: rgba(245, 162, 64, 0.3);
    --history-file-events-primary_16: rgba(245, 162, 64, 0.16);
    --history-file-events-primary_04: rgba(245, 162, 64, 0.04);
    --history-file-events-bg_gradient: linear-gradient(
        90deg,
        #fef8f0 0%,
        rgba(254, 248, 240, 0.3) 100%
    );

    --history-file-other-strong: #1f453d;
    --history-file-other-primary_100: rgba(213, 131, 86, 1);
    --history-file-other-primary_30: rgba(213, 131, 86, 0.3);
    --history-file-other-primary_16: rgba(213, 131, 86, 0.16);
    --history-file-other-primary_04: rgba(213, 131, 86, 0.04);
    --history-file-other-bg_gradient: linear-gradient(
        90deg,
        #fcf5f1 0%,
        rgba(252, 245, 241, 0.3) 100%
    );

    --history-file-others-strong: #1f453d;
    --history-file-others-primary_100: rgba(213, 131, 86, 1);
    --history-file-others-primary_30: rgba(213, 131, 86, 0.3);
    --history-file-others-primary_16: rgba(213, 131, 86, 0.16);
    --history-file-others-primary_04: rgba(213, 131, 86, 0.04);
    --history-file-others-bg_gradient: linear-gradient(
        90deg,
        #fcf5f1 0%,
        rgba(252, 245, 241, 0.3) 100%
    );

    /* journey */

    --journey-car_profile-primary_100: #336c74;
    --journey-car_profile-primary_200: #1f4247;
    --journey-car_profile-primary_300: #336c74;
    --journey-car_profile-primary_400: #b3e5ec;
    --journey-car_profile-primary_500: #81d4df;
    --journey-car_profile-primary_500_90: #81d4dfe5;
    --journey-car_profile-primary_500_80: #81d4dfcc;
    --journey-car_profile-primary_500_70: #81d4dfb2;
    --journey-car_profile-primary_500_60: #81d4df99;
    --journey-car_profile-primary_500_50: rgba(129, 212, 223, 0.5);
    --journey-car_profile-primary_500_24: #81d4df3d;
    --journey-car_profile-primary_500_16: rgba(129, 212, 223, 0.16);
    --journey-car_profile-primary_500_08: #2f5359;
    --journey-car_profile-primary_600: #81d4df;
    --journey-car_profile-primary_700: #81d4df;
    --journey-car_profile-primary_900: #81d4df;

    --journey-history_file-primary_100: #e7f0fb;
    --journey-history_file-primary_200: #dbe8f8;
    --journey-history_file-primary_300: #2e4f77;
    --journey-history_file-primary_400: #a4c6ee;
    --journey-history_file-primary_500: #67a0e3;
    --journey-history_file-primary_500_90: rgba(103, 160, 227, 0.9);
    --journey-history_file-primary_500_80: rgba(103, 160, 227, 0.8);
    --journey-history_file-primary_500_70: rgba(103, 160, 227, 0.7);
    --journey-history_file-primary_500_60: rgba(103, 160, 227, 0.6);
    --journey-history_file-primary_500_50: rgba(103, 160, 227, 0.5);
    --journey-history_file-primary_500_24: rgba(103, 160, 227, 0.24);
    --journey-history_file-primary_500_16: rgba(103, 160, 227, 0.16);
    --journey-history_file-primary_500_08: rgba(103, 160, 227, 0.08);
    --journey-history_file-primary_600: #4a7fbe;
    --journey-history_file-primary_700: #4a7fbe;
    --journey-history_file-primary_900: #67a0e3;

    --journey-insurance-primary_100: #3c3c5c;
    --journey-insurance-primary_200: #e9eafc;
    --journey-insurance-primary_300: #656599;
    --journey-insurance-primary_400: #c9cbf7;
    --journey-insurance-primary_500: #a5a8f2;
    --journey-insurance-primary_500_90: #a5a8f2e5;
    --journey-insurance-primary_500_80: #a5a8f2cc;
    --journey-insurance-primary_500_70: #a5a8f2b2;
    --journey-insurance-primary_500_60: #a5a8f299;
    --journey-insurance-primary_500_50: #a5a8f280;
    --journey-insurance-primary_500_24: #a5a8f23d;
    --journey-insurance-primary_500_16: #a5a8f229;
    --journey-insurance-primary_500_08: #a5a8f214;
    --journey-insurance-primary_600: #8e90d8;
    --journey-insurance-primary_700: #8183c5;
    --journey-insurance-primary_900: #a5a8f2;

    --journey-share_your_car-primary_100: #f1e8f9;
    --journey-share_your_car-primary_200: #ebdcf6;
    --journey-share_your_car-primary_300: #e5d4f3;
    --journey-share_your_car-primary_400: #cca9e8;
    --journey-share_your_car-primary_500: #aa6fd8;
    --journey-share_your_car-primary_500_90: #aa6fd8e5;
    --journey-share_your_car-primary_500_80: #aa6fd8cc;
    --journey-share_your_car-primary_500_70: #aa6fd8b2;
    --journey-share_your_car-primary_500_60: #aa6fd899;
    --journey-share_your_car-primary_500_50: #aa6fd880;
    --journey-share_your_car-primary_500_24: #aa6fd83d;
    --journey-share_your_car-primary_500_16: #aa6fd829;
    --journey-share_your_car-primary_500_08: #aa6fd814;
    --journey-share_your_car-primary_500_04: rgba(170, 111, 216, 0.04);
    --journey-share_your_car-primary_600: #9964c3;
    --journey-share_your_car-primary_900: #8958af;

    --journey-past_cars-primary_100: #f4e6ee;
    --journey-past_cars-primary_200: #eedae6;
    --journey-past_cars-primary_300: #ead0df;
    --journey-past_cars-primary_400: #d4a2c0;
    --journey-past_cars-primary_500: #b86496;
    --journey-past_cars-primary_500_90: rgba(184, 100, 150, 1);
    --journey-past_cars-primary_500_80: rgba(184, 100, 150, 0.8);
    --journey-past_cars-primary_500_70: rgba(184, 100, 150, 0.7);
    --journey-past_cars-primary_500_60: rgba(184, 100, 150, 0.6);
    --journey-past_cars-primary_500_50: rgba(184, 100, 150, 0.5);
    --journey-past_cars-primary_500_24: rgba(184, 100, 150, 0.24);
    --journey-past_cars-primary_500_16: rgba(184, 100, 150, 0.16);
    --journey-past_cars-primary_500_08: rgba(184, 100, 150, 0.08);
    --journey-past_cars-primary_600: #a75b88;
    --journey-past_cars-primary_900: #733e5d;

    --journey-handover-primary_100: #ebf6ec;
    --journey-handover-primary_200: #e2f2e3;
    --journey-handover-primary_300: #daefdc;
    --journey-handover-primary_400: #b6dfb9;
    --journey-handover-primary_500: #85c98b;
    --journey-handover-primary_500_90: rgba(133, 201, 139, 0.9);
    --journey-handover-primary_500_80: rgba(133, 201, 139, 0.8);
    --journey-handover-primary_500_70: rgba(133, 201, 139, 0.7);
    --journey-handover-primary_500_60: rgba(133, 201, 139, 0.6);
    --journey-handover-primary_500_50: rgba(133, 201, 139, 0.5);
    --journey-handover-primary_500_24: rgba(133, 201, 139, 0.24);
    --journey-handover-primary_500_16: rgba(133, 201, 139, 0.16);
    --journey-handover-primary_500_08: rgba(133, 201, 139, 0.8);
    --journey-handover-primary_600: #72af77;
    --journey-handover-primary_900: #38563b;

    --journey-sell_your_car-primary_100: #152e29;
    --journey-sell_your_car-primary_200: #d0e1de;
    --journey-sell_your_car-primary_300: #1f453d;
    --journey-sell_your_car-primary_400: #8bb5ac;
    --journey-sell_your_car-primary_500: #3d8374;
    --journey-sell_your_car-primary_500_90: #3d8374e5;
    --journey-sell_your_car-primary_500_80: #3d8374cc;
    --journey-sell_your_car-primary_500_70: #3d8374b2;
    --journey-sell_your_car-primary_500_60: #3d837499;
    --journey-sell_your_car-primary_500_50: #3d837480;
    --journey-sell_your_car-primary_500_24: #3d83743d;
    --journey-sell_your_car-primary_500_16: #3d837429;
    --journey-sell_your_car-primary_500_08: #152e29;
    --journey-sell_your_car-primary_600: #3d8374;
    --journey-sell_your_car-primary_700: #3d8374;
    --journey-sell_your_car-primary_900: #3d8374;

    --journey-your_profile-primary_100: #edf2f1;
    --journey-your_profile-primary_200: #e4eceb;
    --journey-your_profile-primary_300: #536563;
    --journey-your_profile-primary_400: #bccecc;
    --journey-your_profile-primary_500: #90aeaa;
    --journey-your_profile-primary_500_90: rgba(144, 174, 170, 0.9);
    --journey-your_profile-primary_500_80: rgba(144, 174, 170, 0.8);
    --journey-your_profile-primary_500_70: rgba(144, 174, 170, 0.7);
    --journey-your_profile-primary_500_60: rgba(144, 174, 170, 0.6);
    --journey-your_profile-primary_500_50: rgba(144, 174, 170, 0.5);
    --journey-your_profile-primary_500_24: rgba(144, 174, 170, 0.24);
    --journey-your_profile-primary_500_16: #edf2f1;
    --journey-your_profile-primary_500_08: #f6f9f8;
    --journey-your_profile-primary_600: #7f9a97;
    --journey-your_profile-primary_700: #7f9a97;
    --journey-your_profile-primary_900: #536563;
}

[data-rsbs-overlay] {
    border-top-left-radius: var(--rsbs-overlay-rounded, 16px);
    border-top-right-radius: var(--rsbs-overlay-rounded, 16px);
    display: flex;
    background: var(--rsbs-bg, #fff);
    flex-direction: column;
    height: var(--rsbs-overlay-h, 0px);
    transform: translate3d(0, var(--rsbs-overlay-translate-y, 0px), 0);
    will-change: height;
}

[data-rsbs-overlay]:focus {
    outline: none;
}

[data-rsbs-overlay],
[data-rsbs-root]:after {
    max-width: var(--rsbs-max-w, auto);
    margin-left: var(--rsbs-ml, env(safe-area-inset-left));
    margin-right: var(--rsbs-mr, env(safe-area-inset-right));
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
    z-index: 20;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    touch-action: none;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

[data-rsbs-backdrop] {
    top: 0;
    bottom: 0;
    background-color: var(--rsbs-backdrop-bg, rgba(0, 0, 0, 0.6));
    will-change: opacity;
    cursor: pointer;
    opacity: 1;
}

[data-rsbs-is-dismissable='false'] [data-rsbs-backdrop] {
    cursor: ns-resize;
}

[data-rsbs-root]:after {
    content: '';
    pointer-events: none;
    background: var(--rsbs-bg, #fff);
    height: 1px;
    transform-origin: bottom;
    transform: scale3d(1, var(--rsbs-antigap-scale-y, 0), 1);
    will-change: transform;
}

[data-rsbs-footer],
[data-rsbs-header] {
    flex-shrink: 0;
    cursor: ns-resize;
}

[data-rsbs-header] {
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    /* box-shadow: 0 1px 0 rgba(46, 59, 66, 0.125); */
    z-index: 20;
    padding-top: calc(20px + env(safe-area-inset-top));
}
[data-rsbs-header]:before {
    position: absolute;
    content: '';
    display: block;
    width: 36px;
    height: 4px;
    top: calc(8px + env(safe-area-inset-top));
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    background-color: var(--rsbs-handle-bg, hsla(0, 0%, 0%, 0.14));
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    [data-rsbs-header]:before {
        transform: translateX(-50%) scaleY(0.75);
    }
}
[data-rsbs-has-header='false'] [data-rsbs-header] {
    box-shadow: none;
    padding-top: calc(12px + env(safe-area-inset-top));
}

[data-rsbs-scroll] {
    flex-shrink: 1;
    flex-grow: 1;
    -webkit-tap-highlight-color: revert;
    -webkit-touch-callout: revert;
    -webkit-user-select: auto;
    -ms-user-select: auto;
    -moz-user-select: auto;
    user-select: auto;
    overflow: auto;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
}

[data-rsbs-scroll]:focus {
    outline: none;
}

[data-rsbs-has-footer='false'] [data-rsbs-content] {
    padding-bottom: env(safe-area-inset-bottom);
}

[data-rsbs-content] {
    /* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
    overflow-y: auto;
}

[data-rsbs-footer] {
    box-shadow: 0 -1px 0 rgba(46, 59, 66, 0.125), 0 2px 0 var(--rsbs-bg, #fff);
    overflow: hidden;
    z-index: 1;
}
[data-rsbs-is-dismissable='true'] [data-rsbs-header] > *,
[data-rsbs-is-dismissable='true'] [data-rsbs-scroll] > *,
[data-rsbs-is-dismissable='true'] [data-rsbs-footer] > *,
[data-rsbs-is-dismissable='false'][data-rsbs-state='opening']
    [data-rsbs-header]
    > *,
[data-rsbs-is-dismissable='false'][data-rsbs-state='closing']
    [data-rsbs-header]
    > *,
[data-rsbs-is-dismissable='false'][data-rsbs-state='opening']
    [data-rsbs-scroll]
    > *,
[data-rsbs-is-dismissable='false'][data-rsbs-state='closing']
    [data-rsbs-scroll]
    > *,
[data-rsbs-is-dismissable='false'][data-rsbs-state='opening']
    [data-rsbs-footer]
    > *,
[data-rsbs-is-dismissable='false'][data-rsbs-state='closing']
    [data-rsbs-footer]
    > * {
    opacity: 1;
}

[data-rsbs-is-dismissable='true'] [data-rsbs-backdrop],
[data-rsbs-is-dismissable='false'][data-rsbs-state='opening']
    [data-rsbs-backdrop],
[data-rsbs-is-dismissable='false'][data-rsbs-state='closing']
    [data-rsbs-backdrop] {
    opacity: 1;
}
[data-rsbs-state='closed'],
[data-rsbs-state='closing'] {
    /* Allows interactions on the rest of the page before the close transition is finished */
    pointer-events: none;
}

.css-skeleton-custom {
    background-color: #eee;
    background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100% !important;
    height: 100% !important;
    animation: animation-bzdot9 1.2s ease-in-out infinite;
}

.holds-the-iframe-desktop-homepage {
    background: url(./public/assets/placeholder/grey_placeholder.svg);
    height: 50%;
    width: 90%;
    position: absolute;
    top: 10%;
    left: 3%;
    z-index: -1;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

html {
    --antd-wave-shadow-color: #5ec3ca;
}

::selection {
    color: #fff;
    background: #5ec3ca;
}

.ant-message-notice-content {
    background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
    color: var(--text-strong, #1a1a1a) !important;
}

#google_btn div div div div div:last-child > div {
    background-color: transparent !important;
}

@font-face {
    font-family: 'Lato-Bold';
    src: local('Lato-Bold'), url(./fonts/Lato-Bold.woff) format('opentype');
}

@font-face {
    font-family: 'Lato-Italic';
    src: local('Lato-Italic'), url(./fonts/Lato-Italic.woff) format('opentype');
}

@font-face {
    font-family: 'Lato-Light';
    src: local('Lato-Light'), url(./fonts/Lato-Light.woff) format('opentype');
}

@font-face {
    font-family: 'Lato';
    src: local('Lato-Regular'),
        url(./fonts/Lato-Regular.woff) format('opentype');
}

@font-face {
    font-family: 'Lato-Thin';
    src: local('Lato-Thin'), url(./fonts/Lato-Thin.woff) format('opentype');
}

@font-face {
    font-family: 'Lato-Thin-Italic';
    src: local('Lato-Thin-Italic'),
        url(./fonts/Lato-Thin-Italic.woff) format('opentype');
}

@font-face {
    font-family: 'Lato-Semibold';
    src: local('Lato-Semibold'),
        url(./fonts/Lato-Semibold.woff) format('opentype');
}

@font-face {
    font-family: 'Cinzel';
    font-weight: 200;
    src: local('Cinzel-Regular'),
        url(./fonts/Cinzel-Regular.woff) format('opentype');
}

@font-face {
    font-family: 'CinzelBold';
    font-weight: 200;
    src: local('Cinzel-Bold'), url(./fonts/Cinzel-Bold.woff) format('opentype');
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins-Regular'),
        url(./fonts/Poppins-Regular.woff) format('opentype');
}

@font-face {
    font-family: 'Poppins-Semibold';
    src: local('Poppins-Semibold'),
        url(./fonts/Poppins-Semibold.woff) format('opentype');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: local('Poppins-Bold'),
        url(./fonts/Poppins-Bold.woff) format('opentype');
}

@font-face {
    font-family: 'UK-Number-Plate';
    src: local('UK-Number-Plate'),
        url(./fonts/UKNumberPlate.ttf) format('opentype');
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.pac-container {
    background-color: var(--bg-color, #fff) !important;
    border-color: var(--border-muted, #e5e5e5) !important;
}
.pac-item {
    color: var(--text-darker, #616161) !important;
    border-color: var(--border-muted, #e5e5e5) !important;
}

.pac-item-query {
    color: var(--text-strong, #1a1a1a) !important;
}

.pac-item:hover {
    background-color: var(--off-bg-color, #fafafa) !important;
}
