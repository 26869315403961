.loader-wrapper-inpage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--off-bg-color, fafafa);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}
.loader {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    border: 4px solid #64c5cd;
    animation: loader 2s infinite ease;
    border-radius: 3px;
}

.loader-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    background-color: #64c5cd;
    animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(180deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-inner {
    0% {
        height: 0%;
    }

    25% {
        height: 0%;
    }

    50% {
        height: 100%;
    }

    75% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}

.loading-text {
    text-align: center;
    font-family: 'Lato' !important;
    text-transform: uppercase;
    color: #6bafb5;
    padding-top: 30px;
    font-size: 11px;
}

.loading-text-lighter {
    text-align: center;
    font-family: 'Lato' !important;
    text-transform: capitalize;
    color: #6bafb5;
    padding-top: 3px;
    font-size: 11px;
}
