

@media (min-width: 30em) {
    p {
      font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      
        letter-spacing: 5e-5em;
      
        color: #666666;

        margin-top: 0em;
        margin-bottom: 0em;
    }

    h1 {
        font-size: 2em;
        font-family: Lato;
        margin-bottom: 0em;
        margin-top: 0em;
    }

    h2 {
        font-size: 1.6em;
        font-family: Lato;
        margin-bottom: 0em;
        margin-top: 0em;
    }
}

@media (max-width: 30em) {
    p {
        font-family: Lato;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        
          letter-spacing: 5e-5em;
        
          color: #666666;
          
        margin-bottom: 0em;
        margin-top: 0em;
    }

    h1 {
        font-size: 1.6em;
        font-family: Lato;
        margin-bottom: 0em;
        margin-top: 0em;
    }

    h2 {
        font-size: 1.3em;
        font-family: Lato;
        margin-bottom: 0em;
        margin-top: 0em;
    }
}
