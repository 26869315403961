.ant-upload-list-item-name {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    padding-left: 0px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #a1a1a1 !important;
}

.anticon-paper-clip {
    display: none !important;
}

.ant-upload-list-item-card-actions {
    position: absolute;
    right: 0;
    opacity: 1 !important;
}

.ant-upload-list-item {
    position: relative;
    height: 22px;
    margin-top: 30px !important;
    font-size: 14px;
}

.ant-message-notice {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.ant-message-notice-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-color, #fff);
    min-width: 20vw;
    width: auto;
    padding-right: 50px;
    margin-top: 0vh;
}

.ant-message-custom-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ant-message-error {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.anticon {
    margin-right: 10px;
    padding-left: 5px !important;
    background-color: var(--bg-color, #fff);
}

@media screen and (min-width: 881px) {
    .ant-upload-list {
        display: none !important;
    }

    .ant-upload-list-item {
        display: none !important;
    }
    .ant-upload-list-item-done {
        display: none !important;
    }
    .ant-upload-list-item-list-type-text {
        display: none !important;
    }
}
@media screen and (max-width: 330px) {
    .am-image-picker-list .am-image-picker-item {
        width: 50px !important;
        height: 50px !important;
    }
}

@media screen and (min-width: 330px) {
    .am-image-picker-list .am-image-picker-item {
        width: 80px !important;
        height: 80px !important;
    }
}

.am-flexbox {
    overflow: visible !important;
}

.am-image-picker-list {
    padding-left: 0px !important;
}

/* NOTE: DONT CHANGE THE ORDER OF THESE IT'S IMPORTANT */
@media screen and (max-width: 330px) {
    .am-image-picker-list .am-image-picker-upload-btn:before,
    .am-image-picker-list .am-image-picker-upload-btn:after {
        width: 2px !important;
        height: 20px !important;
        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #5ec3ca !important;
    }
}
@media screen and (min-width: 330px) {
    .am-image-picker-list .am-image-picker-upload-btn:before,
    .am-image-picker-list .am-image-picker-upload-btn:after {
        width: 2px !important;
        height: 25px !important;
        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #5ec3ca !important;
    }
}

.am-image-picker-list .am-image-picker-upload-btn {
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #5ec3ca !important;
    background-color: transparent !important;
}
@media screen and (min-width: 330px) {
    .am-image-picker-list .am-image-picker-upload-btn:after {
        width: 50px !important;
        height: 2px !important;
    }
}

@media screen and (max-width: 330px) {
    .am-image-picker-list .am-image-picker-upload-btn:after {
        width: 20px !important;
        height: 2px !important;
    }
}

@media screen and (min-width: 330px) {
    .am-image-picker-list .am-image-picker-upload-btn:after {
        width: 25px !important;
        height: 2px !important;
    }
}

#onboarding_image_picker_mobile .ant-upload .ant-upload-select-picture-card {
    border-radius: 3px;
    background-color: #fafafa !important;
    border: none !important;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
}

#onboarding_image_picker_mobile .am-image-picker-list .am-image-picker-item {
    transform: translate(0);
    border: none !important;
    background-color: transparent !important;
    width: 40vw !important;
    top: 0;
}

#onboarding_image_picker_mobile_new
    .am-image-picker-list
    .am-image-picker-item {
    transform: translate(0);
    border: none !important;
    background-color: transparent !important;
    width: 88vw !important;
    height: 56px !important;
    top: 0;
}

#onboarding_image_picker_mobile_new
    .am-image-picker-list
    .am-image-picker-upload-btn:before,
#onboarding_image_picker_mobile_new
    .am-image-picker-list
    .am-image-picker-upload-btn:after {
    background-color: transparent !important;
}

#onboarding_image_picker_desktop
    .am-image-picker-list
    .am-image-picker-upload-btn:before,
.am-image-picker-list .am-image-picker-upload-btn:after {
    background-color: #5ec3ca !important;
}

#onboarding_image_picker_desktop .ant-upload .ant-upload-select-picture-card {
    border-radius: 3px;
    background-color: #fafafa !important;
    border: none !important;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
}

#onboarding_image_picker_desktop .am-image-picker-list .am-image-picker-item {
    transform: translate(0);
    border: none !important;
    background-color: transparent !important;
    width: 210px !important;
    top: 10px;
}

#upload_banner_btn_dektop .ant-upload {
    width: 100%;
    box-sizing: border-box;
}
