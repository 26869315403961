#timeline-overview-carousel {
    width: 100% !important;

    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
    transform: none !important;
}
#timeline-overview-carousel .timeline-overview-blue-bullet-class {
    position: relative;
    width: 22px;
    height: 36px;
    border: 7px solid var(--bg-color, #fff);
    background-color: var(--border-muted, #e5e5e5);
    border-radius: 10px;
    opacity: 1;
    transform: none !important;
    left: auto !important;
}

#timeline-overview-carousel .timeline-overview-active-bullet-class {
    position: relative;
    width: 22px;
    height: 36px;
    border: 7px solid var(--bg-color, #fff);
    background-color: var(--text-muted);
    border-radius: 10px;
    opacity: 1;
    opacity: 1;
    transform: none !important;
    left: auto !important;
}

.dark #timeline-overview-carousel .timeline-overview-active-bullet-class {
    position: relative;
    width: 22px;
    height: 36px;
    border: 7px solid var(--bg-color, #fff);
    background-color: var(--border-muted, #e5e5e5);
    border-radius: 10px;
    opacity: 1;
    opacity: 1;
    transform: none !important;
    left: auto !important;
}

/* #timeline-overview-carousel .timeline-overview-active-bullet-class::before {
    position: absolute;
    content: '';
    top: 0px;
    left: 2px;
    width: 2px;
    height: 10px;
    background-color: #5ec3ca;
    opacity: 1;
    transform: none !important;
    border-radius: 50%;
} */
