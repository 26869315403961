.ant-checkbox-wrapper {
    border-top: none;
    border-left: none;
    border-right: none;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--text-strong, #1a1a1a) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary, #5ec3ca) !important;
    border-color: var(--primary, #5ec3ca) !important;
}

.ant-checkbox-checked {
    background-color: var(--primary, #5ec3ca) !important;
    border-color: var(--primary, #5ec3ca) !important;
}

.ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border: 2px solid var(--primary, #5ec3ca) !important;
    border-radius: 3px;
    background-color: transparent !important;
}

.ant-checkbox-checked::after {
    border: 2px solid var(--primary, #5ec3ca) !important;
}

.ant-checkbox-checked .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 2px solid var(--primary, #5ec3ca) !important;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
}

.ant-checkbox-checked::after {
    border-radius: 3px;
}

.ant-checkbox {
    border-radius: 3px;
}

.ant-radio-inner::before {
    background-color: var(--primary, #5ec3ca) !important;
}

.ant-radio-inner::after {
    background-color: var(--primary, #5ec3ca) !important;
}

html {
    --antd-wave-shadow-color: var(--primary, #5ec3ca) !important;
}

::selection {
    background: var(--primary, #5ec3ca) !important;
}

.ant-radio-checked::after {
    border: 1px solid var(--primary, #5ec3ca) !important;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--primary, #5ec3ca) !important;
}

:where(.css-dev-only-do-not-override-1wwf28x).ant-radio-wrapper
    .ant-radio-checked
    .ant-radio-inner {
    border-color: var(--primary, #5ec3ca) !important;
}

.ant-radio-group .ant-radio-group-outline {
    display: flex;
}

#account_deletion .ant-radio-inner {
    height: 20px;
    width: 20px;
}

#account_deletion .ant-radio-inner::before {
    background-color: var(--bg-color, #fff) !important;
    height: 18px;
    width: 18px;
}

#account_deletion .ant-radio-inner::after {
    background-color: var(--primary, #5ec3ca) !important;
    transform: scale(0.7);
}

#account_deletion .ant-radio-checked .ant-radio-inner {
    background-color: var(--primary, #5ec3ca) !important;
    border: 1px solid var(--primary, #5ec3ca) !important;
}

#insurance_radio .ant-radio-inner {
    background-color: var(--off-bg-color, #fafafa) !important;
    border: 1px solid var(--text-defsult, #666666) !important;
    height: 20px;
    width: 20px;
}

#insurance_radio .ant-radio-inner::before {
    background-color: var(--off-bg-color, #fafafa) !important;
    height: 14px;
    width: 14px;
}

#insurance_radio .ant-radio-inner::after {
    background-color: var(--off-bg-color, #fafafa) !important;
    transform: scale(0.7);
}

#insurance_radio .ant-radio-checked .ant-radio-inner {
    background-color: var(--primary, #5ec3ca) !important;
    border: 1px solid var(--primary, #5ec3ca) !important;
}
