.anticon {
    margin-right: -20px;
    padding-left: 215px;
}

.anticon-check {
    margin-right: 0px;
    padding-left: 260px;
}

/* .ant-select-arrow .ant-select-arrow-icon {
    margin-right: 0px;
    padding-left: 260px;
} */

.ant-select-arrow {
    right: 30px !important;
    color: var(--primary, #5ec3ca) !important;
}

.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple > ul > li {
    min-height: 30px;
    margin-top: 7px;
    line-height: 28px;
}

.ant-select-selection--multiple {
    height: 100%;
    min-height: 46px;
    line-height: 46px;
    padding-top: 5px;
    padding-bottom: 10px;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active,
.ant-select-open .ant-select-selection {
    border-color: var(--primary, #5ec3ca) !important;
    height: 100%;
    min-height: 46px !important;
    line-height: 46px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: transparent;
    border: 1px solid transparent !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-selection-placeholder {
    flex: 1 1;
    overflow: hidden;
    color: var(--primary_strong) !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
    font-size: 14px !important;
}

.ant-select-dropdown {
    background-color: var(--off-bg-color, #fafafa) !important;
}

.ant-select-item-option-content {
    color: var(--primary, #5ec3ca) !important;
}

.ant-select-item {
    color: var(--text-strong, #1a1a1a) !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: var(--primary_08, rgba(94, 195, 202, 0.08)) !important;
}

.ant-select-multiple.ant-select-disabled.ant-select:not(
        .ant-select-customize-input
    )
    .ant-select-selector {
    background: var(--primary_08, rgba(94, 195, 202, 0.08)) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: var(--primary_08, rgba(94, 195, 202, 0.08)) !important;
}

.ant-select-multiple .ant-select-selection-item {
    background: var(--primary_08, rgba(94, 195, 202, 0.08)) !important;
    border-color: var(--primary_20);
    color: var(--text-strong, #1a1a1a) !important;
}
.anticon {
    background-color: transparent !important;
}

.anticon svg {
    fill: var(--primary_strong, 009ea4) !important;
}
