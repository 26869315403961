.ant-picker {
    border-color: var(--border-muted, #e5e5e5) !important;
}

.ant_date_dropdown_custom_today_text
    .ant-picker-panel-container
    .ant-picker-panel
    .ant-picker-footer
    .ant-picker-today-btn {
    color: var(--primary, #5ec3ca) !important;
}

.ant-picker-now-btn {
    color: var(--primary, #5ec3ca) !important;
}

.ant-picker-input > input {
    color: var(--text-strong, #1a1a1a) !important;
}

.ant-picker-input {
    color: var(--text-strong, #1a1a1a) !important;

    input::placeholder {
        color: var(--text-strong, #1a1a1a) !important;
    }
}

.ant-picker-panel-container {
    background-color: var(--bg-color, #fff) !important;
}

.ant-picker-header,
.ant-picker-panel,
.ant-picker-panel .ant-picker-footer {
    border-color: var(--border-muted, #e5e5e5) !important;
}

.ant-picker-cell-in-view,
.ant-picker-content th {
    color: var(--text-strong, #1a1a1a) !important;
}

.ant-picker-cell-disabled {
    color: var(--text-muted, #b3b3b3) !important;
    background-color: var(--off-bg-color, #fafafa) !important;
}

.ant-picker-header button {
    color: var(--text-muted, #b3b3b3) !important;
}

.ant-picker-header-view > button {
    color: var(--text-strong, #1a1a1a) !important;
}

.react-datepicker {
    background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
    color: var(--text-strong, #1a1a1a) !important;
    box-shadow: 2px 4px 7px var(--box-shadow, rgba(0, 0, 0, 0.08)) !important;
    border-color: var(--border-muted, #e5e5e5) !important;
}

.react-datepicker__header {
    background-color: var(--off-bg-color, #fafafa) !important;
    border-color: var(--border-muted, #e5e5e5) !important;
}

.react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    border-bottom-color: var(--off-bg-color, #fafafa) !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day {
    color: var(--text-strong, #1a1a1a) !important;
}

.react-datepicker-year-header {
    color: var(--text-strong, #1a1a1a) !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: var(--primary, #5ec3ca) !important;
}

.react-datepicker__month .react-datepicker__month-text:hover,
.react-datepicker__month .react-datepicker__quarter-text:hover {
    background-color: var(--primary_16, #5ec3ca) !important;
}

.react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before {
    border-top-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
}

.react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::after {
    border-top-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
}

.react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::after {
    color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
}

.ant-popover-inner {
    background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
}

.ant-popover-arrow-content:before {
    background: var(--modal-side-sheet-pop-over-bg, #fff) !important;
}

.ant-btn-dangerous {
    background: transparent !important;
}

.ant-btn-primary {
    color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
}

.ant-tooltip {
    color: var(--text-strong, #1a1a1a) !important;
}
