.apple-auth-btn {
    width: 100% !important;
    height: 100% !important;
    font-size: 15px !important;
}

.react-apple-signin-auth-btn-dark {
    border-color: var(--border-muted, #e5e5e5) !important;
}

.react-apple-signin-auth-btn-dark:hover {
    background-color: #333 !important;
    transition: all 200ms;
}
