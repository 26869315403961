#blue-border-select > div > span > span > svg {
    margin-top: 3px;
    margin-left: 10px;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: #00aabb;
}

.blue-border-select-dropdown
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    font-weight: bold;
    background-color: #e6f7ff;
}

/* Chrome, Safari, Edge, Opera */
#blue-border-number-input::-webkit-outer-spin-button,
#blue-border-number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
#blue-border-number-input {
    -moz-appearance: textfield;
}

.ant_date_dropdown_custom_blue_border
    .ant-picker-panel-container
    .ant-picker-panel
    .ant-picker-footer
    .ant-picker-today-btn {
    color: #5ec3ca !important;
}
