@media (max-width: 576px) {
    .ant-picker-panels {
        display: flex !important;
        flex-direction: column !important;
    }

    .ant-picker-panel-container .ant-picker-panels {
        flex-direction: column !important;
        display: flex !important;
    }
}

.ant-picker-range-arrow:before {
    background: var(--bg-color, #fff) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
    background: var(--primary_20);
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: var(--primary, #5ec3ca) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
        .ant-picker-cell-range-start-single
    ):before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
        .ant-picker-cell-range-end-single
    ):before {
    background: var(--primary_20, rgba(94, 195, 202, 0.2)) !important;
}
