.ant-slider-handle {
    position: absolute;
    width: 20px !important;
    height: 20px !important;
    margin-top: -8px;
    background-color: #ffffff !important;
    border: solid 5px #5ec3ca !important;
    border-radius: 50%;
    -webkit-box-shadow: 0;
    box-shadow: 0;
    cursor: pointer;
    -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.6s,
        -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: border-color 0.3s, -webkit-box-shadow 0.6s,
        -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: border-color 0.3s, box-shadow 0.6s,
        transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: border-color 0.3s, box-shadow 0.6s,
        transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28),
        -webkit-box-shadow 0.6s,
        -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.ant-slider-track {
    position: absolute;
    height: 4px;
    background-color: #5ec3ca !important;
    border-radius: 4px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}
