.ant-select-selection__placeholder {
    color: black;
}

.ant-select-selection-item {
    text-transform: capitalize;
    color: var(--text-strong, #1a1a1a) !important;
}

.ant-select-selection {
    font-family: Lato;
}

@media screen and (max-width: 30em) {
    .ant-select-selection {
        height: 44px;
        line-height: 44px;
    }

    .ant-select-selection__rendered {
        height: 100%;
        min-height: 46px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

@media screen and (min-width: 30em) {
    .ant-select-selection {
        height: 46px;
        line-height: 46px;
    }

    .ant-select-selection__rendered {
        height: 100%;
        min-height: 46px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active,
    .ant-select-open .ant-select-selection {
        border-color: #5ec3ca;
        height: 100%;
        min-height: 46px;
        line-height: 46px;
    }
}

.ant-select-dropdown-menu-item {
    font-family: Lato;
}
@media screen and (max-width: 1200px) and (min-width: 500px) {
    .ant-select {
        min-width: 240px;
    }
}

@media screen and (max-width: 499px) {
    .ant-select {
        min-width: auto;
    }
}
