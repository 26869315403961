.holds-the-iframe-mobile {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 40px;
    border-radius: 10px;
    overflow: hidden;
}

.holds-the-iframe-desktop {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
}
