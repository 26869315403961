[data-tour-elem='controls'] {
    justify-content: center;
}

[data-tour-elem='badge'] {
    font-family: Lato;
    border-radius: 50% !important;
    width: 30px !important;
    height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1599px) {
    .first-step-car-desktop {
        width: 200px !important;
        position: absolute;
        left: 0px;
        bottom: 0px;
        top: 0px;
        height: 100%;
    }
}

@media only screen and (min-width: 1600px) {
    .first-step-car-desktop {
        width: 280px !important;
        position: absolute;
        left: 0px;
        bottom: 0px;
        top: 0px;
        height: 100%;
    }
}
