.currency_number_form {
    max-width: 100px !important;
}
@media screen and (max-width: 1200px) and (min-width: 500px) {
    .ant-select .currency_number_form {
        max-width: 100px !important;
    }
}

.currency_number_form_desktop {
    max-width: 180px !important;
    min-width: 180px !important;
}

@media screen and (max-width: 1200px) and (min-width: 500px) {
    .ant-select .currency_number_form_desktop {
        min-width: 180px !important;
    }
}
