@media (min-width: 1200px) {
    #timeline_entry_notes .quill .ql-container .ql-editor {
        height: 65vh;
    }
}

@media (min-width: 800px) {
    #timeline_entry_notes .quill .ql-toolbar {
        border-top: none;
        border-right: none;
        border-left: none;
    }

    #timeline_entry_notes .quill .ql-container {
        border: none;
        padding-top: 20px;
        height: 100%;
        width: 100% !important;
        max-height: none !important;
    }

    #timeline_entry_notes .quill .ql-container .ql-editor {
        height: 60vh;
    }
}

@media (min-width: 1800px) {
    #timeline_entry_notes .quill .ql-container .ql-editor {
        height: 70vh;
    }
}

/* scrollbar styles */
/* width */
#timeline_entry_notes .quill .ql-container .ql-editor::-webkit-scrollbar {
    width: 5px;
}

/* Track */
#timeline_entry_notes .quill .ql-container .ql-editor::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
#timeline_entry_notes .quill .ql-container .ql-editor::-webkit-scrollbar-thumb {
    background: #ebebeb;
    border-radius: 50px;
}

/* Handle on hover */
#timeline_entry_notes
    .quill
    .ql-container
    .ql-editor::-webkit-scrollbar-thumb:hover {
    background: #e4e4e4;
}

#timeline_entry_notes_mobile .quill .ql-container .ql-editor {
    height: 70vh;
}

#timeline_entry_notes_mobile .quill .ql-toolbar {
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 24px;
    border-top: transparent;
    border-right: transparent;
    border-left: transparent;
}

#timeline_entry_notes_mobile .quill .ql-container {
    border: transparent;
}
