.ant-message-notice-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-color, #fff);
    width: auto !important;
    padding-right: 50px !important;
    margin-top: 0vh;
}
