#showroom-range .ant-slider-handle {
    position: absolute;
    width: 14px !important;
    height: 14px !important;
    margin-top: -3px;
    background-color: #ffffff !important;
    border: solid 5px #5ec3ca !important;
    border-radius: 50%;
    -webkit-box-shadow: 0;
    box-shadow: 0;
    cursor: pointer;
    -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.6s,
        -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: border-color 0.3s, -webkit-box-shadow 0.6s,
        -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: border-color 0.3s, box-shadow 0.6s,
        transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: border-color 0.3s, box-shadow 0.6s,
        transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28),
        -webkit-box-shadow 0.6s,
        -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

#showroom-range .ant-slider-handle::after {
    width: 8px;
    height: 8px;
    transform: translate(-2px, -2px);
}
