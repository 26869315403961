.slider-custom-sr {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    cursor: pointer;
    position: relative;
}

.slide-sr {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.custom-dot-carousel-sr {
    height: 4px;
    width: 26px;
    border-radius: 4px !important;
    transition: width 0.25s ease-in-out, background-color 100ms ease-in-out;

    background-color: #686868;
    border-radius: 50%;
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    border: none;
}

.custom-dot-carousel-sr:hover {
    background-color: #5ec3ca;
}

.custom-dot-carousel-sr.carousel__dot--selected {
    background-color: #5ec3ca !important;
    opacity: 1 !important;
    height: 5px !important;
    width: 60px !important;

    border-radius: 4px;
    display: inline-block;
    border: none;
}

@media screen and (min-width: 881px) and (max-width: 1399px) {
    .slider-custom-sr {
        height: 350px;
    }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
    .slider-custom-sr {
        height: 450px;
    }
}

@media screen and (min-width: 1601px) {
    .slider-custom-sr {
        height: 500px;
    }
}

@media screen and (min-width: 2000px) {
    .slider-custom-sr {
        height: 600px;
    }
}
