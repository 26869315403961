[data-tour-elem='controls'] {
    justify-content: center;
}

[data-tour-elem='right-arrow'] {
    display: none !important;
}

[data-tour-elem='left-arrow'] {
    display: none !important;
}
