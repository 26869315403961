.lightui1-shimmer {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #d8d8d8;
    background-image: linear-gradient(
        to right,
        #d8d8d8 0%,
        #bdbdbd 20%,
        #d8d8d8 40%,
        #d8d8d8 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 10vh;
    height: 10vh;
    position: relative;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

@keyframes prideShimmer {
    from {
        background-position: top left;
    }
    to {
        background-position: top right;
    }
}
