:root {
    --swiper-navigation-size: 44px;
    --swiper-navigation-color: #5ec3ca !important;
    --swiper-theme-color: #5ec3ca !important;
}

.swiper-pagination-bullet {
    @media screen and (max-width: 360px) {
        width: 6px !important;
        height: 6px !important;
    }
}

.swiper-button-next {
    display: none !important;
}

.swiper-button-prev {
    display: none !important;
}

.swiper-scrollbar {
    display: none !important;
}

.swiper-button-next-custom {
    display: none;
}

.swiper-button-prev-custom {
    display: none;
}

.swiper-pagination {
    display: block;
    z-index: 200 !important;
}

.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    height: auto;
}

.swiper-row-custom-2 {
    display: flex !important;
    flex-direction: row !important;
    width: auto !important;
}

.swiper-button-next-custom-3 {
    display: none;
}

.swiper-button-prev-custom-3 {
    display: none;
}

.swiper-container-custom-3 {
    background-color: #fbffff;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
}

.swiper-row-custom-3 {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    width: 286px !important;
    height: 450px !important;
    transform: translateX(20px);
    padding-top: 40px;
}

.swiper-pagination-progressbar {
    height: 2px !important;
    top: 0px !important;
    background: rgb(132 132 132 / 25%) !important;
}

.swiper-slide .swiper-row-custom-3 .swiper-slide-next {
    transform: translateX(-43px);
}
