:root {
    --swiper-navigation-size: 44px;
    --swiper-navigation-color: #5ec3ca !important;
    --swiper-theme-color: #5ec3ca !important;
}

.swiper-pagination-bullet {
    @media screen and (max-width: 360px) {
        width: 6px !important;
        height: 6px !important;
    }
}

.swiper-button-next {
    display: none !important;
}

.swiper-button-prev {
    display: none !important;
}

.swiper-scrollbar {
    display: none !important;
}

.swiper-button-next-custom {
    display: none;
}

.swiper-button-prev-custom {
    display: none;
}

/* .slide {
    height: 50vh !important;
} */

.swiper-container-custom-2 {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    height: 50vh;
}

.slider-custom-2 {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    cursor: pointer;
}

@media screen and (max-width: 1400px) {
    .slider-custom-2 {
        width: 100%;
        box-sizing: border-box;
        min-height: 400px;
        max-height: 400px;
    }
}

@media screen and (min-width: 1400px) {
    .slider-custom-2 {
        width: 100%;
        box-sizing: border-box;

        min-height: 500px;
        max-height: 500px;
    }
}

@media screen and (min-width: 1600px) {
    .slider-custom-2 {
        min-height: 600px;
        max-height: 600px;
        width: 72vw;
    }
}

.swiper-pagination {
    display: block;
    z-index: 200 !important;
}

.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    height: auto;
}

/* .custom-dots-group-2 {
    display: none;
} */
/* .custom-dots-group-2 {
    position: absolute;
    z-index: 5 !important;
    width: 100%;
    box-sizing: border-box;
    height: 20px;
    margin-top: 46vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
}

@media screen and (max-width: 1600px) {
    .custom-dots-group-2 {
        margin-top: 40vw;
    }
} */

.custom-dot-carousel {
    height: 8px;
    width: 16px;
    transition: width 0.25s ease-in-out;

    background-color: #cccccc;
    border-radius: 8px;
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    border: none;
    z-index: 5 !important;
}

.carousel__dot--selected {
    background-color: #5ec3ca !important;
    opacity: 1 !important;
    height: 8px !important;
    width: 56px;
    background-color: #5ec3ca;
    border-radius: 8px;
    display: inline-block;
    border: none;
}

.carousel__next-button {
    background-color: transparent;
    border: none;
    padding: 0px;

    transition: all 0.1s;
    outline: 0;
}

.carousel__back-button {
    background-color: transparent;
    border: none;
    padding: 0px;

    transition: all 0.1s;
    outline: 0;
}

.swiper-row-custom-2 {
    display: flex !important;
    flex-direction: row !important;
    width: auto !important;
}

.swiper-button-next-custom-2 {
    display: none;
}

.swiper-button-prev-custom-2 {
    display: none;
}
