.ant-modal-body {
    padding: 0px !important;
}

@media screen and (max-width: 880px) {
    .ant-modal-wrap {
        display: none !important;
    }
    .ant-modal-mask {
        display: none !important;
    }
}
