[data-rmiz-btn-open],
[data-rmiz-btn-close] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    font: inherit;
    color: inherit;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
}
