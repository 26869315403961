#custom_search_mobile_bar .ant-select-selector {
    border-color: none !important;
    box-shadow: none;
}

.custom-search-timeline-bar-mobile {
    background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
    left: 0 !important;
    width: 100vw !important;
    top: 120px !important;
    position: absolute;
    box-shadow: none;
    font-family: Lato !important;
}

.custom-search-timeline-bar-mobile .custom-tag-filter-option-mobile {
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    height: 44px;
    -webkit-tap-highlight-color: var(
        --bg-color-70,
        rgba(255, 255, 255, 0.7)
    ) !important;
}

.custom-search-timeline-bar-mobile .custom-category-filter-option-mobile {
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    height: 44px;
    -webkit-tap-highlight-color: var(
        --bg-color-70,
        rgba(255, 255, 255, 0.7)
    ) !important;
}

.custom-search-timeline-bar-mobile .custom-searchfor-filter-option-mobile {
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    height: 44px;
    -webkit-tap-highlight-color: var(
        --bg-color-70,
        rgba(255, 255, 255, 0.7)
    ) !important;
}

.custom-tag-filter-option-mobile .ant-select-item-option-content {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding-left: 10px;
    padding-right: 10px;
}

.custom-searchfor-filter-option-mobile .ant-select-item-option-content {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding-left: 10px;
    padding-right: 10px;
}

.custom-category-filter-option-mobile .ant-select-item-option-content {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding-left: 10px;
    padding-right: 10px;
}

.custom-option-label-timeline-mobile {
    color: var(--text-muted, '#b3b3b3');
    font-weight: 200 !important;
    padding-right: 5px;
}
