/* smallest_laptops_ends */

.reactEasyCrop_Container {
    background-color: var(
        --background-overlay-light,
        rgba(255, 255, 255, 0.96)
    );
}

.sliders-container-desktop {
    background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
}

@media (min-width: 881px) and (max-width: 1200px) {
    .reactEasyCrop_CropArea {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid var(--border-muted, #e5e5e5);
        box-sizing: border-box;
        box-shadow: 0 0 0 9999em;
        color: var(--text-darker, #616161);
        overflow: hidden;
        max-height: 350px;
        max-width: 80vw;
    }

    .reactEasyCrop_Container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        user-select: none;
        touch-action: none;
        cursor: move;
        height: 100%;
        /* min-height: 350px; */
        min-height: 55vh;
        max-height: 55vh;
    }

    .inner-form-padding-desktop {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* height: auto; */
        color: var(--text-strong, #1a1a1a);
        border-radius: 5px;
        position: relative;
        /* margin-top: 5vh; */
        width: 80vw;
        min-width: 670px;
        /* height: auto; */
    }

    .sliders-container-desktop {
        height: 280px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        position: absolute;
        /* top: 350px; */
        top: 56vh;
        padding-left: 10%;
        padding-right: 10%;
    }
}

/* smallest_laptops_ends */

@media (min-width: 1201px) and (max-width: 1400px) {
    .reactEasyCrop_CropArea {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid var(--border-muted, #e5e5e5);
        box-sizing: border-box;
        box-shadow: 0 0 0 9999em;
        color: rgba(0, 0, 0, 0.5);
        overflow: hidden;
        max-width: 80vw;
    }

    .reactEasyCrop_Container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        user-select: none;
        touch-action: none;
        cursor: move;
        height: 100%;
        /* min-height: 500px; */
        min-height: 55vh;
        max-height: 55vh;
    }

    .inner-form-padding-desktop {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* height: auto; */
        color: var(--text-strong, #1a1a1a);
        border-radius: 5px;
        position: relative;
        /* margin-top: 10px; */
        width: 80vw;
        min-width: 670px;
        background-color: var(--bg-color, #fff);
    }

    .sliders-container-desktop {
        height: 280px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        /* top: 500px; */
        top: 55vh;
        padding-left: 10%;
        padding-right: 10%;
    }
}

/* smallest_laptops_ends */

@media (min-width: 1401px) and (max-width: 1705px) {
    .reactEasyCrop_CropArea {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid var(--border-muted, #e5e5e5);
        box-sizing: border-box;
        box-shadow: 0 0 0 9999em;
        color: var(--text-darker, #616161);
        overflow: hidden;
        max-width: 80vw;
    }

    .reactEasyCrop_Container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        user-select: none;
        touch-action: none;
        cursor: move;
        height: 100%;
        /* min-height: 600px; */
        min-height: 55vh;
        max-height: 55vh;
    }

    .inner-form-padding-desktop {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* height: auto; */
        color: var(--text-strong, #1a1a1a);
        border-radius: 5px;
        position: relative;
        /* margin-top: 8vh; */
        width: 80vw;
        min-width: 670px;
        background-color: var(--bg-color, #fff);
    }

    .sliders-container-desktop {
        height: 280px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        /* top: 600px; */
        top: 55vh;
        padding-left: 10%;
        padding-right: 10%;
    }
}

/* proper monitors start */

@media (min-width: 1706px) {
    .reactEasyCrop_CropArea {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid var(--border-muted, #e5e5e5);
        box-sizing: border-box;
        box-shadow: 0 0 0 9999em;
        color: var(--text-darker, #616161);
        overflow: hidden;
        max-width: 80vw;
    }

    .reactEasyCrop_Container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        user-select: none;
        touch-action: none;
        cursor: move;
        /* min-height: 800px; */
        min-height: 55vh;
        max-height: 55vh;
    }

    .inner-form-padding-desktop {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* height: auto; */
        color: var(--text-strong, #1a1a1a);
        border-radius: 5px;
        position: relative;
        /* margin-top: 10vh; */
        width: 80vw;
        min-width: 670px;
    }
    .sliders-container-desktop {
        height: 280px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        /* top: 800px; */
        top: 55vh;
        padding-left: 10%;
        padding-right: 10%;
    }
}

.ant-slider-rail {
    background-color: var(--border-muted, #e5e5e5);
}

.inner-form-padding-desktop .ant-slider-handle {
    background-color: var(--bg-color, #fff) !important;
}
