@media (min-width: 1201px) {
    .ql-container {
        font-size: 16px;
        font-family: Lato;
        width: 100%;
        box-sizing: border-box;
        max-width: 1400px !important;
        min-height: 100px;
        height: 35vh;
        max-height: 300px;
        -webkit-overflow-scrolling: touch;
        color: var(--text-strong, #1a1a1a);
        background-color: var(--bg-color, #fff) !important;
    }

    .ql-editor p {
        line-height: 2 !important;
    }
}

@media (max-width: 1200px) {
    .ql-container {
        font-size: 14px;
        font-family: Lato;
        width: 100%;
        box-sizing: border-box;
        min-height: 50vh;
        -webkit-overflow-scrolling: touch;
        color: var(--text-strong, #1a1a1a);
        background-color: var(--bg-color, #fff);
        border-color: var(--border-muted, #e5e5e5) !important;
    }

    .ql-editor p {
        line-height: 1.6 !important;
    }

    .ql-editor {
        padding-top: 30px;
    }

    .ql-toolbar {
        width: 100%;
        box-sizing: border-box;
        background-color: var(--bg-color, #fff);
        z-index: 1;
        border-color: var(--border-muted, #e5e5e5) !important;
    }
}

@media (min-width: 1800px) {
    .ql-container {
        font-size: 16px;
        font-family: Lato;
        color: var(--text-strong, #1a1a1a);
        border-color: var(--border-muted, #e5e5e5);
        width: 100%;
        box-sizing: border-box;
        max-width: 1400px !important;
        min-height: 100px;
        height: 24vh;
    }

    .ql-editor p {
        line-height: 2 !important;
    }
}

.ql-editor h1 {
    font-size: 1.6em !important;
    font-family: Lato;
}

.ql-editor h2 {
    font-size: 1.3em !important;
    font-family: Lato;
}

.ql-header .ql-fill {
    fill: var(--text-strong, #1a1a1a);
}

.ql-toolbar .ql-stroke {
    stroke: var(--text-strong, #1a1a1a);
}

.ql-formats .ql-strike .ql-fill {
    fill: var(--text-strong, #1a1a1a);
}

.ql-formats .ql-picker-options {
    background-color: var(--bg-color, #fff);
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: var(--border-muted, #e5e5e5);
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: var(--border-muted, #e5e5e5);
}

.ql-editor p,
h1,
h2,
li {
    color: var(--text-strong, #1a1a1a);
}

.ql-editor p,
h1,
h2,
li {
    color: var(--text-strong, #1a1a1a);
}

.ql-editor.ql-blank::before {
    color: var(--text-muted);
}

#toolbarHiddenDesktopDescription {
    display: none;
}
